import React from 'react';
// import smallBrand from '../assets/images/nav_brand.png';

class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showMobileNav: false };
  }

  toggleMobileNav = () => {
    this.setState({ showMobileNav: !this.state.showMobileNav });
  }

  render() {
    const burgerClass = `burger ${this.state.showMobileNav ? 'active' : ''}`;
    return (
      <div id="nav-bar">
        <div className="container">
          <div className="nav-bar-inner">
            <label onClick={this.toggleMobileNav} className={burgerClass}>
              <span className="top"></span>
              <span className="middle"></span>
              <span className="bottom"></span>
            </label>
            <nav id='navigation' role="navigation">
              {this.props.children}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
