import React from 'react';
import Navigation from '../library/Navigation';
import { Link } from 'gatsby';
import logo from '../assets/images/logo.png';

export default function NavBar(props) {
  const isPartiallyActive = ({
    isPartiallyCurrent
  }) => {
    return isPartiallyCurrent
      ? { className: "active" }
      : null
  }
  const PartialNavLink = props => (
    <Link getProps={isPartiallyActive} {...props} />
  )
  
  return (
    <Navigation>
      <Link to="/"><img className="logo" src={logo} alt="Logo TCM Praxis Paola Nakahara"/></Link>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><PartialNavLink to="/ueber-mich/">Über mich</PartialNavLink></li>
        <li><PartialNavLink to="/angebot/">Angebot</PartialNavLink></li>
        <li><PartialNavLink to="/anwendungen/">Indikation</PartialNavLink></li>
        <li><PartialNavLink to="/kosten/">Kosten</PartialNavLink></li>
        <li><PartialNavLink to="/blog/">Blog</PartialNavLink></li>
        <li><PartialNavLink to="/kontakt/">Kontakt & Termine</PartialNavLink></li>
        <li><PartialNavLink to="/netzwerk/">Netzwerk</PartialNavLink></li>
      </ul>
    </Navigation>
  );
}


