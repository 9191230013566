import { useEffect, useState } from 'react'

function CookieConsent() {
  const [consent, setConsent] = useState(false)

  useEffect(() => {
    setConsent(true)
  }, [])

  useEffect(() => {
    if (consent) {
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
      })
    }  
  }, [consent])

  return null
}

export default CookieConsent
