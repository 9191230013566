import React from 'react'
import Helmet from 'react-helmet'
import NavBar from '../components/NavBar';
import ContactBar from '../components/ContactBar';

import LogoTree from '../assets/images/Logo_tree.png';
import LogoText from '../assets/images/Logo_text.png';

import '../assets/styles/site.scss';
import CookieConsent from './CookieConsent';

const Layout = ({ children, location }) => { 
  const isClient = typeof window !== 'undefined' && process.env.NODE_ENV === 'production'

  return (
    <div className='layout'>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Paola Nakahara" />
        <meta property="og:url" content={`https://paolanakahara.ch${location.pathname}`} />
        <link rel="canonical" href={`https://paolanakahara.ch${location.pathname}`} />
        <meta name="google-site-verification" content="WDark3UGMO4OdRuh6nyjxFZAkxwaLfBbOqqB01RD71M" />
        
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Droid+Sans%3Aregular%2C700%7CSource+Sans+Pro%3A200%2C200italic%2C300%2C300italic%2Cregular%2Citalic%2C600%2C600italic%2C700%2C700italic%2C900%2C900italic&ver=34d4e208fbccfffc648688a25d05d20a#038;subset=latin,cyrillic,greek,vietnamese,greek-ext,latin-ext,cyrillic-ext" type="text/css" media="all" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700" type="text/css" media="all" />
        <link href="https://fonts.googleapis.com/css?family=Kreon:300,400,700" rel="stylesheet" />
        
        <html lang="de" />
      </Helmet>
      <img className="logo-tree" src={LogoTree} alt="Logo TCM Praxis Paola Nakahara" />
      <img className="logo-text" src={LogoText} alt="Logo TCM Praxis Paola Nakahara" />
      <div id="splash"><div>KK<br/> anerkannt</div></div>
      <ContactBar className="small-screen" />
      <NavBar />
      {children}
      {isClient && <CookieConsent />}
    </div>
  )
}

export default Layout;
