import React from 'react';

export default function ContactBar(props) {
  const className = props.className;
  
  return (
    <div id="contact-bar" className={className}>
        <div className="container">
          <ul className="info">
            <li><a href="phone:+41762134087"><i className="fa fa-phone" /> +41 76 213 40 87</a></li>
            <li><a href="mailto:tcm@paolanakahara.ch"><i className="fa fa-envelope" /> tcm@paolanakahara.ch</a></li>
          </ul>
        </div>
    </div>
  );
}
